import { useEffect, useRef, useState } from "react";
import banner from "../../../Assets/HomePageAssets/appolinary-kalashnikova-WYGhTLym344-unsplash 1-1.png";
import video from "../../../Assets/videos/Y2meta.app - Salesbox - Sales Simplified in 2 Minutes.mp4";
import { externalLinks } from "../../../constants/external-links";
const HeroSectionV2 = () => {
  const [showOverlay, setShowOverlay] = useState(false);


  return (
    <>
      <section
        className={`w-full  h-[75vh] relative z-0 overflow-hidden bg-right bg-cover`}
        style={{
          backgroundImage: `url('${banner}')`,
        }}
      >
        <div className="w-full max-w-[1560px] mx-auto h-full  py-8 px-4 lg:px-16 relative z-[1]  flex flex-col justify-center gap-10">
          <div className="mb-10">
            <h3 className="text-xl sm:text-2xl lg:text-4xl text-white">
              Welcome To Salesbox
            </h3>
            <h1 className="font-bold text-5xl lg:text-7xl text-white mb-8 mt-2">
              Elevate Your <br /> B2B Sales.
            </h1>
            <a href={externalLinks.calendly.salesbox} rel="noopener noreferrer" target="_blank">
              <button className="text-lime hover:before:bg-lime border-lime-500 relative h-[40px] w-64 overflow-hidden border  bg-transparent px-3 text-white shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-lime-500 before:transition-all before:duration-250 hover:text-white hover:shadow-lime-700 hover:before:left-0 hover:before:w-full">
                <span className="relative z-10">Learn More</span>
              </button>
            </a>
            <a href="http://" target="_blank" rel="noopener noreferrer"></a>
          </div>
          <div

            onClick={() => setShowOverlay(true)}
            className="w-max bottom-10 absolute group cursor-pointer  flex  gap-4 items-center  "
          >
            <div className=" relative  transition-all ease-in-out duration-300 ">
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="group-hover:drawn group-hover:stroke-lime-500  "
                  d="M28.3666 35.4769C28.3666 38.8059 28.3666 42.1349 28.3666 45.464C28.3666 47.3134 29.3969 47.8683 31.036 46.9435C34.8761 44.7704 38.7162 42.5973 42.5563 40.3779C44.6168 39.222 46.6774 38.0661 48.6911 36.864C50.0492 36.078 50.0492 34.8758 48.6911 34.0436C48.5974 33.9973 48.4569 33.9048 48.3633 33.8586C42.6031 30.5758 36.7961 27.293 31.036 24.0102C29.3969 23.0855 28.4134 23.6404 28.3666 25.4898C28.3666 28.8651 28.3666 32.1941 28.3666 35.4769Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
                <circle
                  className=" group-hover:stroke-lime-500  "
                  cx="36"
                  cy="36"
                  r="32.5"
                  stroke="white"
                  strokeWidth="3"
                />
              </svg>
            </div>
            <h4 className="opacity-100 relative   group-hover:text-primary stroke-primary transition-all ease-in-out duration-300  hashTag textshadow font-semibold mt-1 text-2xl text-white drop-shadow-lg shadow-black">
              Play 2 min Video
            </h4>
          </div>
        </div>
      </section>

      {/* video modal */}
      <div
        className={`w-screen h-screen   fixed top-0 left-0 bg-black z-[5000]  flex items-center justify-center transition-all ease-in-out duration-300 ${showOverlay
          ? "opacity-100 pointer-events-auto "
          : "opacity-0 pointer-events-none"
          }`}
        onClick={() => setShowOverlay(false)}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full z-[5000] flex items-center justify-center p-4 lg:p-20 mx-auto  relative"
        >
          <button
            className="text-white absolute    top-28 right-16 z-50 fill-white hover:fill-lime-500 transition-all duration-300 ease-in"
            onClick={() => setShowOverlay(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="20px"
              height="20px"
            >
              <path
                d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                fill=""
              />
            </svg>
          </button>
          {showOverlay && (
            // <iframe
            //   className="w-[calc(100px*3)] h-[calc(100px*2)] md:w-[calc(200px*3)] md:h-[calc(200px*2)] lg:w-[calc(300px*3)] lg:h-[calc(300px*2)] 2xl:w-[calc(300px*3)] 2xl:h-[calc(300px*2)]"
            //   src="https://www.youtube.com/embed/pEkt29HyPCg"
            //   title="Salesbox - Sales Simplified in 2 Minutes"
            //   frameborder="0"
            //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            //   allowfullscreen
            // ></iframe>
            <video src={video} controls autoPlay className="aspect-video w-full lg:w-[96%] cursor-pointer z-20" ></video>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroSectionV2;
