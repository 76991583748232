export const routes = {
  home: "/",
  salesSimplified: "/sales-simplified",
  campaigns: "/campaigns",
  experience: "/experience",
  testimonials: "/testimonials",
  leadership: "/leadership",
  salesmrkt: "https://salesmrkt.com/",
  marketplace: "https://salesboxmarket.myshopify.com/",
  finTech: "/services/fin-tech",
  foodBeverage: "/services/food-beverage",
  healthcare: "/services/healthcare",
  hr: "/services/hr",
  marketing: "/services/marketing",
  professionalServices: "/services/professional-services",
  techSaas: "/services/tech-saas",
};
